@import url("./tailwind.css");
@import url("./swiper.css");
@import url("./scroll.css");
@import url("./colour.css");
@import url("./antd.css");
@import url("./screen.css");
@import url("./wangeditor.css");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* PingFang HK */
@font-face {
  font-family: 'PingFangHK';
  src: url("https://info-catnip-prod-public.s3.ap-east-1.amazonaws.com/PingFangHK-Light.otf");
}

* {
  font-family: 'PingFangHK', sans-serif;
}

/* SourceHanSerif HK-Bold */
@font-face {
  font-family: 'SourceHanSerifHK';
  src: url('https://info-catnip-prod-public.s3.ap-east-1.amazonaws.com/SourceHanSerifHK-Bold.otf') format('opentype');
}

.source-han-serif-hk {
  font-family: 'SourceHanSerifHK', sans-serif!important;
}

.book-title {
  writing-mode: vertical-rl;
  overflow-wrap: break-word;
  letter-spacing: 0.3em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 初始化 */
a,
a:hover {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--color-primary);
  line-height: 1.75rem;
}

h1 {
  font-size: 2.5em; /* 设置 h1 的字体大小为 2.5em */
}

h2 {
  font-size: 2em; /* 设置 h2 的字体大小为 2em */
}

h3 {
  font-size: 1.5em; /* 设置 h3 的字体大小为 1.5em */
}

h4 {
  font-size: 1.25em; /* 设置 h4 的字体大小为 1.17em */
}

h5 {
  font-size: 1em; /* 设置 h5 的字体大小为 1em */
}

h6 {
  font-size: 0.83em; /* 设置 h6 的字体大小为 0.83em */
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

p, li {
  color: var(--color-darkGray);
  line-height: 1.75rem;
}

.text-gray {
    color: var(--color-gray);
}


/* 首屏 slogan 响应式缩放 */
@media screen and (min-width: 2560px) {
  #slogan {
    transform: scale(1.5);
  }
}

@media screen and (min-width: 2256px) and (max-width: 2560px) {
  #slogan {
    transform: scale(1.4);
  }
}

@media screen and (min-width: 1920px) and (max-width: 2560px) {
  #slogan {
    transform: scale(1.3);
  }
}

@media screen and (min-width: 1770px) and (max-width: 1920px)  {
  #slogan {
    transform: scale(1.2);
  }
}

@media screen and (min-width: 1440px) and (max-width: 1770px)  {
  #slogan {
    transform: scale(1);
  }
}

@media screen and (max-width: 1440px) {
  #slogan {
    transform: scale(0.8);
  }
}