@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/effect-coverflow';
@import 'swiper/css/pagination';

.swiper{
    height: 100%;
    flex: 1;
    box-sizing: content-box;
}

.swiper .swiper-button-prev,
.swiper .swiper-button-next {
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    background-color: #102e24;
    border-radius: 50%;
}

.swiper .swiper-button-prev::after,
.swiper .swiper-button-next::after {
    color: #fff;
    font-size: 20px;
}

.swiper-button-disabled {
    visibility: hidden;
}