/* 默认字体大小 */
html {
    font-size: 16px!important;
}

/* 小屏幕 */
@media screen and (max-width: 1536px) {
    .collectible,
    .collectible-slide {
        width: 260px;
        height: 342px;
    }
    #hero {
        margin-bottom: calc(260px / (319/418));
    }
}

/* 大屏幕 */
@media screen and (min-width: 1536px) and (max-width: 1920px) {
    html {
        font-size: 18px!important;
    }
    .collectible,
    .collectible-slide {
        width: 320px;
        height: 420px;
    }
    #hero {
        margin-bottom: calc(320px / (319/418));
    }
}

/* 超大屏幕 */
@media screen and (min-width: 1920px) {
    html {
        font-size: 20px!important;
    }
    .collectible,
    .collectible-slide {
        width: 400px;
        height: 525px;
    }
    #hero {
        margin-bottom: calc(400px / (319/418));
    }
}