/* 定义滚动条轨道的样式 */
::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* 轨道的背景色 */
}

/* 定义滚动条的样式 */
::-webkit-scrollbar {
    width: 8px; /* 纵向滚动条的宽度 */
    height: 0px; /* 横向滚动条的高度 */
    background-color: #f1f1f1; /* 滚动条的背景色 */
}

/* 定义滚动条滑块的样式 */
::-webkit-scrollbar-thumb {
    background-color: #888; /* 滑块的背景色 */
    border-radius: 4px; /* 滑块的圆角半径 */
}

/* 鼠标悬停在滚动条上时的样式 */
::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* 悬停时滑块的背景色 */
}