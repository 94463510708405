.w-e-bar {
    padding: 0!important;
    background-color: #102e24!important;
}

.w-e-bar-item {
    padding: 0 5px!important;
}

.w-e-bar-item .active {
    background-color: #102e24!important;
}

#chapter-preview em {
    color: #102e24;
}

div[data-w-e-textarea="true"] {
    padding: 0!important;
}

.w-e-scroll {
    padding: 0 10% !important;
}

.w-e-scroll em {
    border-radius: 10px;
    padding: .1rem!important;
    background: linear-gradient(white, white) padding-box, 
                linear-gradient(to right, #EEE0C9, #ECBCBB, #B4ADEE, #B4D5E5, #F1E8CC) border-box;
    border: 2px solid transparent;
    font-style: normal;
    user-select: none;
}

.w-e-scroll span[data-slate-string="true"] {
    line-height: 2.8rem;
}


.toolbar{
    overflow: hidden;
}

.toolbar>.w-e-toolbar{
    flex-wrap: nowrap;
}

@media (max-width: 600px) {
    .toolbar button{
        background-size: 12px !important;
        margin-inline: 0px;
    }
    .toolbarButton{
        height: 13px;
        margin-inline: 0px;
    }
}

@media (min-width: 601px) and (max-width: 1024px) {
    .toolbar button{
        background-size: 15px !important;
        margin-inline: 4px;
    }
    .toolbarButton{
        height: 15px;
        margin-inline: 4px;
    }
}

@media (min-width: 1025px) {
    .toolbar button{
        background-size: 18px !important;
        margin-inline: 8px;
    }
    .toolbarButton{
        height: 18px;
        margin-inline: 8px;
    }
}



button[data-menu-key] {
    display: inline-block;
    background-repeat: no-repeat!important;
    background-position: center!important;
}

button[data-menu-key]:hover {
    background-color: #102e24!important;
}

button[data-menu-key] svg {
    display: none;
}

/* 图标 */
button[data-menu-key="bold"] {
    background-image: url('../svg/bold.svg')!important;
}

button[data-menu-key="underline"] {
    background-image: url('../svg/underline.svg')!important;
}
  
button[data-menu-key="undo"] {
    background-image: url('../svg/undo.svg')!important;
}

button[data-menu-key="redo"] {
    background-image: url('../svg/redo.svg')!important;
}

/* 提示 */
button[data-menu-key="bold"]::before {
    content: "粗體";
}

button[data-menu-key="underline"]::before {
    content: "下底線";
}

button[data-menu-key="undo"]::before {
    content: "撤銷";
}

button[data-menu-key="redo"]::before {
    content: "重做";
}

.w-e-bar-item:has(button[data-menu-key="italic"]) {
    display: none!important;
}