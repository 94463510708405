.ant-modal-content {
    padding: 0!important;
    background-color: transparent!important;
    box-shadow: none!important;
}

.ant-btn {
    box-shadow: none;
}

/*表格thead*/
.ant-table-thead > tr > th {
    font-size: 1rem;
    font-weight: normal!important;
}
  
/*表格tbody*/
.ant-table-tbody > tr > td {
    font-size: 1rem;
}

.ant-slider-rail,
.ant-slider-track {
    border-radius: 10px!important;
}

/* 锚点链接 */
.ant-anchor-link {
    padding: 0 !important;
}

.ant-select-selection-item {
    display: flex !important;
    align-items: center !important;
    gap: .3rem;
    height: 3rem !important;
}

/* 小屏幕 */
@media screen and (max-width: 1536px) {
    .collectableSwitch {
        transform: scale(1.2);
    }
}

/* 大屏幕 */
@media screen and (min-width: 1536px) {
    .collectableSwitch {
        transform: scale(1.5);
    }
}

/* 抽屉隐藏滚动条 */
.ant-drawer-body::-webkit-scrollbar {
    display: none;
}