/* src/components/header_mobile/SideMenu.css */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
} 

.sideMenu .ant-drawer-close {
  display: none !important;
}

.sideMenu .ant-drawer-header {
  height: 80px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sideMenu .ant-drawer-title {
  display: none;
}

.sideMenu .ant-drawer-close {
  display: none;
}